<template>
  <div class="page_">
    <div class="tab_">
      <p class="flex_ tab_f">
        <span>{{ $t("OVERSEAS[1]") || "海外代表性网络医院" }}</span>
        <span>|</span>
        <span class="input_">
          <input
            type="text"
            :placeholder="$t('ML1[38]') || '请输入医院名称'"
            v-model="hospitalName"
            @input="searchProviderListByCountry"
          />
        </span>
      </p>
      <div class="sort_">
        <span
          v-for="(item, index) in grapList"
          :key="item"
          :style="{
            width: index == 0 ? '92px' : '24px',
            paddingLeft: index == 0 ? '2px' : ''
          }"
          :class="{ active_: activeIndex == index }"
          @click="grapChange(index, item)"
          >{{ item }}</span
        >
      </div>
      <div class="country_">
        <span
          class="country_item"
          v-for="(item, index) in countryList"
          :key="index"
          :class="{ cactive_: countryIndex == index }"
          @click="countryChange(index, item)"
          >{{ item.list.fdesc }}（{{ item.list.ctrycd }}）</span
        >
      </div>
      <a-row class="list">
        <a-col
          :span="11"
          class="list_item"
          v-for="(item, index) in list"
          :key="index"
          :offset="index % 2 == 1 ? 2 : 0"
          style="margin-top: 20px;"
        >
          <span>{{ item.longName }}</span>
          <span style="color: #999;font-size: 12px;">{{
            item.englishName
          }}</span>
        </a-col>
      </a-row>
    </div>
    <div v-if="list && list.length > 0" class="page_wrap_">
      <a-pagination
        @change="pageChange"
        :total="total"
        :pageSize="10"
        show-quick-jumper
      ></a-pagination>
    </div>
    <div class="noh" v-if="list.length === 0">
      <img src="../../../assets/medilink/noh.png" alt="" />
      <span>暂无数据</span>
    </div>
    <div class="fixbg1" v-if="isLoading">
      <a-spin />
    </div>
  </div>
</template>
<script>
const grapList = [
  "全部（ALL）",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z"
];
export default {
  data() {
    return {
      hospitalName: "",
      ctrycd: "",
      grapList,
      activeIndex: 0,
      countryList: [],
      countryIndex: 0,
      list: [],
      total: 0,
      isLoading: false,
      oriList: [],
      pageNum: "",
      letterList: []
    };
  },
  mounted() {
    this.searchCountryForProviderList();
  },
  methods: {
    findHospitalByName() {},
    grapChange(index, item) {
      this.activeIndex = index;
      let i = this.countryList.findIndex(country => country.name == item);
      this.countryIndex = i;
      if (this.countryIndex != -1) {
        let country = this.countryList[this.countryIndex];
        this.ctrycd = index == 0 ? "" : country.list.ctrycd;
        this.searchProviderListByCountry();
      } else {
        this.list = [];
      }
    },
    countryChange(index, item) {
      this.countryIndex = index;
      this.ctrycd = index == 0 ? "" : item.list.ctrycd;
      this.searchProviderListByCountry();
    },
    pageChange(page, pageSize) {
      this.pageNum = page;
      this.toPage();
    },
    toPage() {
      this.list = JSON.parse(JSON.stringify(this.oriList));
      let page = (this.pageNum - 1) * 10;
      this.list = this.list.splice(page, 10);
    },
    searchCountryForProviderList() {
      this.isLoading = true;
      this.$apis
        .searchCountryForProviderList()
        .then(res => res.data)
        .then(data => {
          this.isLoading = false;
          this.countryList = this.getFirstChar(data.data, "englishName");
          this.countryList.unshift({
            list: {
              ctrycd: "",
              fdesc: "全部",
              ctrycd: "ALL"
            },
            name: ""
          });
          let ctrycd = "";
          this.ctrycd = ctrycd ? ctrycd : "";
          this.searchProviderListByCountry();
        });
    },
    searchProviderListByCountry() {
      this.list = [];
      this.isLoading = true;
      let param = {
        ctrycd: this.ctrycd,
        shortName: this.hospitalName
      };
      this.$apis
        .searchProviderListByCountry(param)
        .then(res => res.data)
        .then(data => {
          this.isLoading = false;
          this.list = data.data;
          this.oriList = JSON.parse(JSON.stringify(data.data));
          this.total = data.data.length;
          this.list = this.list.splice(0, 10);
        });
    },
    getFirstChar(arr, key) {
      let cityJson = [];
      // 根据首字母键值对给原数据按首字母分类
      for (let i = 0; i < this.grapList.length; i++) {
        // 这里的FirstPin是一个写入了所有字母的数组,见data中
        let list = arr.filter(item => {
          let value = item.ctrycd.substring(0, 1);
          if (value == this.grapList[i]) {
            return item;
          }
        });
        if (list.length != 0) {
          for (let j = 0; j < list.length; j++) {
            cityJson.push({
              name: this.grapList[i],
              list: list[j]
            });
          }
        }
      }
      return cityJson;
    }
  }
};
</script>
<style lang="scss" scoped>
.tab_ {
  width: 1000px;
  margin: 0 auto;
  font-size: 14px;
  color: #000;
  position: relative;
  .selectcon_ {
    position: absolute;
    top: 37px;
    left: 170px;
    right: 0;
    background-color: #fff;
    padding: 10px;
    width: 640px;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
    &.selectcon_1 {
      left: 340px;
    }
    &.selectcon_2 {
      left: 400px;
    }
    &.selectcon_3 {
      left: 460px;
    }
    .flex_l,
    .flex_r,
    .list_ {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }
    span {
      width: 25%;
      text-align: left;
      padding: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &.on {
        color: #00a63c;
      }
    }
  }
  .sort_ {
    display: flex;
    justify-content: space-around;
    margin: 0 0 20px -7px;
    cursor: pointer;
    align-items: center;
  }
  .active_ {
    display: inline-block;
    width: 24px;
    border: 1px solid #00a63c;
    text-align: center;
    color: #00a63c;
  }
  .country_ {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    cursor: pointer;
    align-items: center;
    margin-left: -11px;
    .country_item {
      margin-left: 10px;
      margin-bottom: 15px;
    }
  }
  .cactive_ {
    border: 1px solid #00a63c;
    padding: 3px 5px;
    color: #00a63c;
  }
  .list {
    // margin-top: 40px;
    .list_item {
      height: 60px;
      border-bottom: 1px solid #efefef;
      padding-left: 40px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:hover {
        border: 1px solid #00a63c;
      }
      &::before {
        display: block;
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #00a63c;
        top: 47%;
        left: 2%;
      }
    }
  }
}
input {
  border: none;
  background-color: transparent;
}
.page_wrap_ {
  width: 1000px;
  margin: 20px auto;
  text-align: right;
}
.flex_ {
  display: flex;
  align-items: center;
  &.tab_f {
    margin-bottom: 30px;
    > span {
      &:first-child {
        padding-left: 0;
      }
      cursor: pointer;
      padding: 0px 23px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      .icon_arrow {
        width: 7px;
        height: 5px;
        display: inline-block;
        background: url(../../../assets/medilink/downiconon.png) center center
          no-repeat;
        background-size: 7px 5px;
        margin-left: 3px;
      }
      &.input_ input {
        width: 300px;
        padding: 10px 20px;
        height: 100%;
        border-bottom: 1px solid #000;
        background: url(../../../assets/medilink/input.png) left center
          no-repeat;
        background-size: 13px 13px;
        &:focus {
          appearance: none;
          border: none;
          border-bottom: 1px solid #000;
          outline: none;
        }
      }
    }
  }
}
.noh {
  width: 1000px;
  margin: 0 auto;
  height: 500px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 200px;
    margin: 0 auto;
    display: block;
    margin-bottom: 30px;
  }
}
.fixbg1 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  .ant-spin-spinning {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    left: 0;
    margin: auto;
  }
}
</style>
